import { Skeleton } from '@paid-ui/components/skeleton';
import { Tooltip } from '@paid-ui/components/tooltip';
import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { BellIcon } from '@paid-ui/icons/bell';
import { ProjectIcon } from '@paid-ui/icons/project';
import { TargetStageIcon } from '@paid-ui/icons/target-stage';
import { saveProjectSummary } from '@paid-ui/models/project';
import { userManager } from '@paid-ui/models/user';
import { type ProjectSummary } from '@paid-ui/queries/project-list';
import { getFullProjectName } from '@paid-ui/utils/project';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

import { NewProjectButton } from '../new-project-button';
import { QuickActions } from '../quick-actions';
import {
  checkIfActionsEnable,
  checkIfAllClaimsPaid,
  getContractType,
  getProgressPayments,
  getProjectStatus,
} from './utils';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data?: ProjectSummary;
  loading?: boolean;
  badge?: number;
}

export const ProjectCard = forwardRef<HTMLDivElement, Props>(
  ({ data, loading, badge = 0, className, ...restProps }, ref) => {
    const router = useRouter();
    const { isOwner } = useSnapshot(userManager);
    const contractType = getContractType(data);
    const projectStatus = getProjectStatus(data);
    const progressPayments = getProgressPayments(data);
    const numberOfProgressPayments = progressPayments.length;
    const firstPaidIndex = progressPayments.findIndex((payment) => !payment.paid);
    const allClaimsPaid = checkIfAllClaimsPaid(data);
    const actionsEnabled = !allClaimsPaid && checkIfActionsEnable(data);

    if (loading) {
      return (
        <section
          ref={ref}
          className={twMerge(
            'cursor-pointer rounded-lg bg-white shadow transition-shadow duration-150 ease-linear hover:shadow-lg',
            className,
          )}
          {...restProps}
        >
          <div className="flex h-full w-full flex-col">
            <div className="relative h-28 w-full rounded-t-lg bg-grey-hover">
              <div className="absolute inset-x-0 bottom-0 line-clamp-2 px-4 py-[18px] text-xl font-semibold text-grey-dark">
                <Skeleton className="h-[20px] w-[50%]" />
              </div>
            </div>
            <div className="space-y-3.5 px-4 py-[18px]">
              <div className="h-[44px] space-y-2">
                <Skeleton className="h-[16px]" />
                <Skeleton className="h-[16px] w-[30%]" />
              </div>
              <div className="h-[56px] space-y-2">
                <div className="flex items-center justify-between">
                  <span className="flex-1 text-grey-dark">
                    <Skeleton className="h-[14px] w-[60px]" />
                  </span>
                  <span className="shrink-0 text-grey-dark">
                    <Skeleton className="h-[14px] w-[30px]" />
                  </span>
                </div>
              </div>
              <div className="h-9">
                <Skeleton className="h-[34px] w-[34px]" />
              </div>
            </div>
          </div>
        </section>
      );
    }

    if (!data) {
      return (
        <section
          ref={ref}
          className={twMerge(
            'cursor-pointer rounded-lg bg-white shadow transition-shadow duration-150 ease-linear hover:shadow-lg',
            className,
          )}
          {...restProps}
        >
          <div className="flex h-28 w-full flex-col justify-center rounded-t-lg bg-grey-hover px-4 py-[18px]">
            <ProjectIcon className="h-[50px] w-[50px]" />
          </div>
          <div className="space-y-3.5 px-4 py-[18px]">
            <h3 className="line-clamp-3 h-[44px] text-base font-medium leading-snug">
              {isOwner
                ? 'Ask your builder to create first project on Paid.'
                : 'Create your first project.'}
            </h3>
            <div className="h-[56px]" />
            <div className="h-9">
              <NewProjectButton full />
            </div>
          </div>
        </section>
      );
    }

    const handleProjectClick = () => {
      saveProjectSummary(data);
      router.push(`/projects/${data.id}`);
    };

    return (
      <section
        ref={ref}
        className={twMerge(
          'cursor-pointer rounded-lg bg-white shadow transition-shadow duration-150 ease-linear hover:shadow-lg',
          className,
        )}
        {...restProps}
      >
        <div
          role="button"
          onClick={handleProjectClick}
          className="flex h-full w-full cursor-pointer flex-col"
        >
          <div className="relative h-28 w-full rounded-t-lg bg-grey-hover">
            <p className="absolute inset-x-0 bottom-0 line-clamp-2 px-4 py-[18px] text-xl font-semibold text-grey-dark">
              {data.projectAddress.suburb}
            </p>
            {badge ? (
              <UnstyledButton className="pointer-events-none absolute right-3.5 top-3.5 inline-flex items-center gap-1 rounded bg-pink px-1.5 py-1 text-sm font-medium text-white shadow">
                <BellIcon />
                <span>{badge >= 100 ? '99+' : badge}</span>
              </UnstyledButton>
            ) : null}
          </div>
          <div className="space-y-3.5 px-4 py-[18px]">
            <h3 className="line-clamp-3 h-[44px] text-base font-medium leading-snug">
              {getFullProjectName(data.projectName, data.projectNumber)}
            </h3>
            <div className="h-[56px] space-y-2">
              <div className="flex items-center justify-between">
                <span className="flex-1 text-sm font-medium leading-tight text-grey-dark">
                  {contractType}
                </span>
                {allClaimsPaid ? (
                  <span className="shrink-0 rounded bg-green px-2 py-1 text-sm font-medium text-white">
                    All claims paid
                  </span>
                ) : (
                  <span className="shrink-0 text-sm font-normal leading-tight text-grey-dark">
                    {projectStatus}
                  </span>
                )}
              </div>
              {allClaimsPaid || numberOfProgressPayments === 0 ? null : (
                <div className="grid h-6 w-full grid-flow-col gap-x-0.5">
                  {progressPayments.map((payment, index) => (
                    <div key={payment.id} className="relative h-full">
                      {index === firstPaidIndex && numberOfProgressPayments > 1 ? (
                        <TargetStageIcon className="absolute left-[calc(50%-8px)] top-0.5 h-1.5 fill-grey-dark" />
                      ) : null}
                      <Tooltip title={payment.paid ? 'Paid' : 'Unpaid'}>
                        <span
                          className={twMerge(
                            'inline-block h-1 w-full appearance-none transition-transform duration-150 ease-linear hover:scale-y-150',
                            index === 0 ? 'rounded-l-md' : '',
                            index === numberOfProgressPayments - 1 ? 'rounded-r-md' : '',
                            payment.paid ? 'bg-green' : 'bg-grey-mid',
                          )}
                        />
                      </Tooltip>
                      {index === firstPaidIndex && numberOfProgressPayments > 1 ? (
                        <TargetStageIcon className="absolute -bottom-0.5 left-[calc(50%-8px)] h-1.5 rotate-180 fill-grey-dark" />
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="h-9">
              <QuickActions data={data} enabled={actionsEnabled} />
            </div>
          </div>
        </div>
      </section>
    );
  },
);

ProjectCard.displayName = 'ProjectCard';

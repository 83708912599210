'use client';

import * as ProgressPrimitive from '@radix-ui/react-progress';
import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const ProgressRoot = ProgressPrimitive.Root;
const ProgressIndicator = ProgressPrimitive.Indicator;

const progressVariants = cva('relative h-2 w-full overflow-hidden rounded-full bg-grey-mid', {
  variants: {
    transparent: {
      true: 'bg-transparent',
    },
  },
});

const progressIndicatorVariants = cva(
  'ease-[cubic-bezier(0.65, 0, 0.35, 1)] h-full w-full bg-blue transition-transform duration-500',
  {
    variants: {
      color: {
        blue: 'bg-blue',
        green: 'bg-green',
        pink: 'bg-pink',
        grey: 'bg-grey-mid',
      },
    },
  },
);

type ProgressColor = 'blue' | 'green' | 'pink' | 'grey';

export interface ProgressProps
  extends VariantProps<typeof progressVariants>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'color'> {
  value?: number;
  max?: number;
  getValueLabel?: (value: number, max: number) => string;
  color?: ProgressColor | [left: ProgressColor, right: ProgressColor];
}

export const Progress = forwardRef<HTMLDivElement, ProgressProps>((props, ref) => {
  const {
    color = 'blue',
    value = 0,
    max = 100,
    getValueLabel,
    hidden,
    className,
    style,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  if (Array.isArray(color)) {
    const [leftColor, rightColor] = color;
    return (
      <ProgressRoot
        ref={ref}
        value={value}
        max={max}
        getValueLabel={getValueLabel}
        className={twMerge(
          progressVariants({
            transparent: true,
            className,
          }),
        )}
        style={{
          transform: 'translateZ(0)',
          ...style,
        }}
        {...restProps}
      >
        <div
          className="grid h-full w-full"
          style={{
            columnGap: [0, 100].includes(value) ? 0 : 4,
            gridTemplateColumns: `${value}fr ${100 - value}fr`,
          }}
        >
          <ProgressIndicator className={twMerge(progressIndicatorVariants({ color: leftColor }))} />
          <ProgressIndicator
            className={twMerge(progressIndicatorVariants({ color: rightColor }))}
          />
        </div>
      </ProgressRoot>
    );
  }

  return (
    <ProgressRoot
      ref={ref}
      value={value}
      max={max}
      getValueLabel={getValueLabel}
      className={twMerge(progressVariants({ className }))}
      style={{
        transform: 'translateZ(0)',
        ...style,
      }}
      {...restProps}
    >
      <ProgressIndicator
        className={twMerge(progressIndicatorVariants({ color }))}
        style={{
          transform: `translateX(-${100 - value}%)`,
        }}
      />
    </ProgressRoot>
  );
});

Progress.displayName = 'Progress';

import { type ConfirmDialogProps } from '@paid-ui/ui/confirm-dialog';
import { proxy } from 'valtio';

type ConfirmDialogManagerDetails = Omit<
  ConfirmDialogProps,
  'open' | 'onOpenChange' | 'trigger' | 'nested'
>;

export const confirmDialogManager = proxy({
  open: false,
  details: {} as ConfirmDialogManagerDetails,
});

export const openConfirmDialog = <T extends ConfirmDialogManagerDetails>(details?: T) => {
  confirmDialogManager.open = true;
  if (details !== undefined) {
    confirmDialogManager.details = details;
  }
};

export const closeConfirmDialog = () => {
  confirmDialogManager.open = false;
  confirmDialogManager.details = {
    title: '',
  };
};

export const toggleConfirmLoading = (loading: boolean, buttonText: string) => {
  const { open } = confirmDialogManager;
  if (!open) return;
  confirmDialogManager.details.confirmLoading = loading;
  confirmDialogManager.details.okButtonText = buttonText;
};

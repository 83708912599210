import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const containerVariants = cva(
  'flex h-[54px] w-full items-center rounded border border-grey-tertiary bg-white focus-within:border-blue focus-within:shadow-field',
  {
    variants: {
      disabled: {
        true: 'bg-grey-hover',
      },
      readOnly: {
        true: 'bg-grey-hover',
      },
      changed: {
        true: 'border border-purple',
      },
      error: {
        true: 'border border-pink focus-within:border-pink focus-within:shadow-field-error',
      },
      compact: {
        true: 'h-[44px]',
      },
    },
  },
);

const iconVariants = cva(
  'inline-flex h-full shrink-0 select-none items-center justify-center bg-grey-light px-3.5 text-base text-grey-dark empty:hidden',
  {
    variants: {
      disabled: {
        true: 'cursor-not-allowed text-grey-tertiary',
      },
      position: {
        prefix: 'rounded-l border-r border-r-grey-mid',
        suffix: 'rounded-r border-l border-l-grey-mid',
      },
      transparent: {
        true: 'bg-transparent',
      },
      noPadding: {
        true: 'px-0',
      },
    },
    compoundVariants: [
      {
        position: 'prefix',
        transparent: true,
        className: 'border-r-transparent pr-0',
      },
      {
        position: 'suffix',
        transparent: true,
        className: 'border-l-transparent pl-0',
      },
    ],
  },
);

const inputVariants = cva(
  'peer h-full w-full rounded bg-white px-3.5 text-base text-black outline-none transition-colors duration-150 ease-linear placeholder:text-grey-dark disabled:cursor-not-allowed disabled:bg-grey-hover disabled:text-grey-dark read-only:bg-grey-hover disabled:placeholder:text-grey-tertiary read-only:text-grey-dark',
  {
    variants: {
      bordered: {
        true: 'h-[54px] border border-grey-tertiary focus:border-blue focus:shadow-field',
      },
      changed: {
        true: '',
      },
      error: {
        true: '',
      },
      compact: {
        true: '',
      },
    },
    compoundVariants: [
      {
        bordered: true,
        changed: true,
        className: 'border-purple',
      },
      {
        bordered: true,
        error: true,
        className: 'border-pink focus:border-pink focus:shadow-field-error',
      },
      {
        bordered: true,
        compact: true,
        className: 'h-[44px]',
      },
    ],
  },
);

export interface InputProps
  extends VariantProps<typeof inputVariants>,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix' | 'children'> {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  compactPrefix?: boolean;
  compactSuffix?: boolean;
  transparentPrefix?: boolean;
  transparentSuffix?: boolean;
  changed?: boolean;
  error?: boolean;
  selectedOnFocus?: boolean;
  scrollIntoViewOnFocus?: boolean;
  inputClassName?: string;
  prefixClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      prefix,
      suffix,
      compactPrefix,
      compactSuffix,
      transparentPrefix,
      transparentSuffix,
      disabled,
      readOnly,
      changed,
      error,
      compact,
      selectedOnFocus = true,
      scrollIntoViewOnFocus = true,
      autoComplete = 'off',
      hidden,
      className,
      inputClassName,
      prefixClassName,
      onBlur,
      onFocus,
      ...restProps
    },
    ref,
  ) => {
    if (hidden) {
      return null;
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      event.currentTarget.value = event.currentTarget.value.trim();
      onBlur?.(event);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (selectedOnFocus) event.currentTarget.select();
      if (scrollIntoViewOnFocus) {
        const element = event.currentTarget;
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 300);
      }
      onFocus?.(event);
    };

    if (prefix || suffix) {
      return (
        <div
          ref={ref}
          className={twMerge(
            containerVariants({
              disabled,
              readOnly,
              changed,
              error,
              compact,
              className,
            }),
          )}
        >
          <div
            className={twMerge(
              iconVariants({
                disabled,
                position: 'prefix',
                transparent: transparentPrefix,
                noPadding: compactPrefix,
              }),
              prefixClassName,
            )}
          >
            {prefix}
          </div>
          <input
            disabled={disabled}
            readOnly={readOnly}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autoComplete={autoComplete}
            className={twMerge(inputVariants({ className: inputClassName }))}
            {...restProps}
          />
          <div
            className={twMerge(
              iconVariants({
                disabled,
                position: 'suffix',
                transparent: transparentSuffix,
                noPadding: compactSuffix,
              }),
            )}
          >
            {suffix}
          </div>
        </div>
      );
    }

    return (
      <input
        ref={ref}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoComplete={autoComplete}
        className={twMerge(
          inputVariants({
            bordered: true,
            changed,
            error,
            compact,
            className: inputClassName ?? className,
          }),
        )}
        {...restProps}
      />
    );
  },
);

Input.displayName = 'Input';
